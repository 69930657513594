import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Outlook from './Outlook';
import Failed from './Failed';
import AlertDialog from './AlertDialog';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/outlook" render={(props) => <Outlook {...props} />} />
        <Route exact path="/failed" render={(props) => <AlertDialog {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;
